import { IQuizContext } from './QuizContext';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { FormValues } from '@wix/form-viewer/widget';
import { initFormController } from '@wix/form-viewer/controller';
import { createSubmission } from '@wix/ambassador-forms-v4-submission/http';
import { SubmissionStatus } from '@wix/ambassador-forms-v4-submission/types';
import { localStoragePropsMap } from '../LocalStorage/localStoragePropsMap';

export const quizPropsMap = (flowAPI: ControllerFlowAPI): IQuizContext => {
  return {
    controllerIsReady: false,
    initController: async (formId: string) => {
      flowAPI.controllerConfig.setProps({
        controllerIsReady: false,
        lastSubmissionId: null,
      });

      await initFormController(flowAPI, {
        formId,
      });

      flowAPI.controllerConfig.setProps({
        controllerIsReady: true,
      });
    },
    sendSubmission: async (formId: string, values: FormValues) => {
      const { setItem, requestItem } = await localStoragePropsMap(flowAPI);
      const submissionsString = requestItem('oprog-form-quiz-submissions');
      const submissions = submissionsString
        ? JSON.parse(submissionsString)
        : {};

      submissions[formId] = values;
      setItem('oprog-form-quiz-submissions', submissions);

      const sbmsn = await flowAPI.httpClient.request(
        createSubmission({
          submission: {
            formId,
            submissions: values,
            status: SubmissionStatus.PENDING,
          },
        }),
      );

      flowAPI.controllerConfig.setProps({
        lastSubmissionId: sbmsn?.data?.submission?.id,
      });
    },
    lastSubmissionId: null,
  };
};
